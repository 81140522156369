import axios from '@/plugins/axios'

/**
 * Create coupon
 */
const createCoupon = async ({
  name,
  type,
  code,
  amount,
  // eslint-disable-next-line camelcase
  spend_amount,
  // eslint-disable-next-line camelcase
  start_date,
  // eslint-disable-next-line camelcase
  end_date,
  // eslint-disable-next-line camelcase
  max_uses,
  // eslint-disable-next-line camelcase
  is_first_purchase_use,
  // eslint-disable-next-line camelcase
  is_single_use_per_account,
  // eslint-disable-next-line camelcase
  is_single_use_per_advertiser,
  description
}) => {
  try {
    const form = {
      name,
      type,
      code,
      start_date,
      end_date,
      amount: parseInt(amount * 100),
      // eslint-disable-next-line camelcase
      spend_amount: parseInt(spend_amount * 100),
      max_uses,
      is_first_purchase_use,
      is_single_use_per_account,
      is_single_use_per_advertiser,
      description
    }
    const response = await axios.post('admin/coupons', form)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get coupon
 */
const getCoupon = async (id) => {
  try {
    const response = await axios.get(`admin/coupons/${id}`)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get coupon
 */
const getCoupons = async ({
  name = null,
  // eslint-disable-next-line camelcase
  amount_start = 0,
  // eslint-disable-next-line camelcase
  amount_end = 0,
  // eslint-disable-next-line camelcase
  end_date_end = null,
  // eslint-disable-next-line camelcase
  end_date_start = null,
  // eslint-disable-next-line camelcase
  only_used = false,
  page = 1,
  // eslint-disable-next-line camelcase
  start_date_end = null,
  // eslint-disable-next-line camelcase
  start_date_start = null,
  type = null
}) => {
  try {
    const params = {
      page: page
    }
    if (name) params.name_search = name
    // eslint-disable-next-line camelcase
    if (amount_start > 0) params.amount_start = parseInt(amount_start * 100)
    // eslint-disable-next-line camelcase
    if (amount_end > 0) params.amount_end = parseInt(amount_end * 100)
    // eslint-disable-next-line camelcase
    if (end_date_end) params.end_date_end = end_date_end
    // eslint-disable-next-line camelcase
    if (end_date_start) params.end_date_start = end_date_start
    // eslint-disable-next-line camelcase
    if (only_used) params.only_used = only_used
    // eslint-disable-next-line camelcase
    if (start_date_end) params.start_date_end = start_date_end
    // eslint-disable-next-line camelcase
    if (start_date_start) params.start_date_start = start_date_start
    if (type) params.type = type

    const response = await axios.get('admin/coupons', {
      params: params
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Update coupon
 */
const updateCoupon = async (id, {
  name,
  type,
  code,
  amount,
  // eslint-disable-next-line camelcase
  spend_amount,
  // eslint-disable-next-line camelcase
  start_date,
  // eslint-disable-next-line camelcase
  end_date,
  // eslint-disable-next-line camelcase
  max_uses,
  // eslint-disable-next-line camelcase
  is_first_purchase_use,
  // eslint-disable-next-line camelcase
  is_single_use_per_account,
  // eslint-disable-next-line camelcase
  is_single_use_per_advertiser,
  description
}) => {
  try {
    const form = {
      name,
      type,
      code,
      start_date,
      end_date,
      amount: parseInt(amount * 100),
      // eslint-disable-next-line camelcase
      spend_amount: parseInt(spend_amount * 100),
      max_uses,
      is_first_purchase_use,
      is_single_use_per_account,
      is_single_use_per_advertiser,
      description
    }
    form._method = 'PUT'
    const response = await axios.post(`admin/coupons/${id}`, form)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  createCoupon,
  getCoupon,
  getCoupons,
  updateCoupon
}
